<template>
    <v-card flat tile>
        <v-snackbar v-model="showResult" :timeout="30000" top :color="color" tile>
            {{ result }}
        </v-snackbar>
        <v-card-text>
            <v-container>
                <v-form v-model="valid" name="formvin">
                    <h1 class="titleColor">{{ nomduvin }}</h1>
                    <h3> {{ categorie }} {{ appellation }}</h3>
                    <div class="infoVin">
                        <v-row>
                            <v-col cols="auto">
                                <v-carousel height="200" hide-delimiters>
                                    <v-carousel-item key=0 max-width="200"><img width="200" :src="photovin" alt="photovin"/></v-carousel-item>
                                    <v-carousel-item key=1 max-width="200"><img width="200" :src="photoetiquetteface" alt="photoetiquetteface"/></v-carousel-item>
                                    <v-carousel-item key=2 max-width="200"><img width="200" :src="photoetiquettedos" alt="photoetiquettedos"/></v-carousel-item>
                                </v-carousel>
                            </v-col>
                            <v-col>
                                <div style="text-align: center;margin-top: 50px;">
                                    <v-icon size="64" :color="iconColor">
                                        mdi-glass-wine
                                    </v-icon>
                                    <h3>{{ tauxdalcool }} % vol.</h3>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <h2 class="titleColor">Déclaration nutritionnelle</h2>
                    <p class="subtitle">Pour 100ml</p>
                    <div class="infoVin">
                        <v-row>
                            <v-col>
                                <h4>Energie</h4>
                            </v-col>
                            <v-col cols="auto">
                                <p>{{ valenerkj }} kJ / {{ valenerkcal }} kcal</p>
                            </v-col>
                        </v-row>
                        <v-divider style="margin-bottom: 10px;"></v-divider>
                        <v-row>
                            <v-col>
                                <h4>Glucides</h4>
                                <p>dont sucre</p>
                            </v-col>
                            <v-col cols="auto">
                                <p style="margin-bottom: 0;">{{ glucides }} g</p>
                                <p>{{ sucreresiduel }} g</p>
                            </v-col>
                        </v-row>
                        <v-divider style="margin-bottom: 10px;"></v-divider>
                        <v-row>
                            <v-col>
                                <p v-if="checkboxfaiblequant"> Contient de faibles quantités de matières grasses, d'acides
                                    gras saturés, de protéines et de sel</p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <h2 class="titleColor">Ingrédients</h2>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="infoVin" style="font-size:13px">
                        <div v-if="ingredients != ''"><b>Ingrédients</b>: {{ afficheinfo(ingredients) }}</div>
                        <div v-if="liqueurtirage != ''"><b>Liqueur de tirage</b>: {{ afficheinfo(liqueurtirage) }}</div>
                        <div v-if="liqueurexpedition != ''"><b>Liqueur d'éxpedition</b>: {{ afficheinfo(liqueurexpedition) }}
                        </div>
                        <div v-if="regulateursacidite != ''"><b>Régulateur d'acidité</b>: {{ afficheinfo(regulateursacidite) }}</div>
                        <div v-if="conservateuretantioxydants != ''"><b>Conservateurs et antioxydants</b>: {{ afficheInfoConservAntiox(conservateuretantioxydants) }}</div>
                        <div v-if="sequestrants != ''"><b>Séquestrants</b>: {{ afficheinfo(sequestrants) }}</div>
                        <div v-if="activateursfermentation != ''"><b>Activateurs de fermentation alcoolique et malolactique</b>: {{
                            afficheInfoActivateurFerm(activateursfermentation) }}</div>
                        <div v-if="agentsclarifiants != ''"><b>Agents clarifiants</b>: {{ afficheInfoClarifiant(agentsclarifiants) }}</div>
                        <div v-if="agentsstabilisateurs != ''"><b>Agents stabilisateurs</b>: {{ afficheInfoAgentsStab(agentsstabilisateurs) }}</div>
                        <div v-if="enzymes != ''"><b>Enzymes</b>: {{ afficheinfo(enzymes) }}</div>
                        <div v-if="gazetgazemballage != ''"><b>Gaz et gaz d'emballage</b>: {{ afficheinfo(gazetgazemballage) }}</div>
                        <div v-if="agentsfermentation != ''"><b>Agents de fermentation</b>: {{ afficheinfo(agentsfermentation) }}</div>
                        <div v-if="correctiondefauts != ''"><b>Correction des défauts</b>: {{ afficheinfo(correctiondefauts) }}</div>
                        <div v-if="autrespratiques != ''"><b>Autres pratiques</b>: {{ afficheinfo(autrespratiques) }}</div>
                    </div>
                </v-form>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export default {
    name: 'VoirVin',
    data: () => ({
        visuetiquettevin: '',
        valid: false,
        showResult: false,
        color: '',
        result: '',
        imgdisplay: null,
        height: 300,
        arringredients: ['Raisins', 'Moût de raisins', 'Sucre (saccharose)', 'Moût de raisins concentré', 'Liqueur de tirage', 'Liqueur d\'expédition'],
        arrliqueurtirage: [],
        arrliqueurexpedition: [],
        arrregulateursacidite: [],
        arrconservateuretantioxydants: [],
        arrsequestrants: [],
        arractivateursfermentation: [],
        arragentsclarifiants: [],
        arragentsstabilisateurs: [],
        arrenzymes: [],
        arrgazetgazemballage: [],
        arragentsfermentation: [],
        arrcorrectiondefauts: [],
        arrautrespratiques: [],
        arrmillesime: [],
        arrappellation: [],
        arrappellationCopy: [],
        arrcepage: [],
        arrcepageCopy: [],
        arrcouleurs: [],
        arrcouleursCopy: [],
        arrconditionnement: [],
        arrcategorie: ['VSIG (vin sans indication géographique) ou VIN DE TABLE', 'IGP (vin avec indication géographique protégée) ou VIN DE PAYS', 'AOP (vin avec appellation d’origine protégée) ou AOC'],
        qrcode: null,
        photovin: null,
        photovintoupload: null,
        photoetiquetteface: null,
        photoetiquettefacetoupload: null,
        photoetiquettedos: null,
        photoetiquettedostoupload: null,
        etiquetteface: '',
        etiquettedos: '',
        millesime: '',
        categorie: '',
        searchTerm: '',
        searchCepage: '',
        appellation: '',
        cepage: '',
        valenerkj: 0,
        valenerkcal: 0,
        glucides: 0,
        sucreresiduel: 0,
        checkboxfaiblequant: false,
        ingredients: '',
        liqueurtirage: '',
        liqueurexpedition: '',
        regulateursacidite: '',
        conservateuretantioxydants: '',
        sequestrants: '',
        activateursfermentation: '',
        agentsclarifiants: '',
        agentsstabilisateurs: '',
        enzymes: '',
        checkboxatmoprotect: '',
        checkboxmaybeatmoprotect: '',
        gazetgazemballage: '',
        agentsfermentation: '',
        correctiondefauts: '',
        autrespratiques: '',
        couleur: '',
        nomduvin: '',
        numerolot: '',
        commentaire: '',
        conditionnement: '',
        tauxdalcool: '',
        image: null,
        imagetoupload: null,
        iconColor: 'white'
    }),
    async mounted() {
        await this.getappellations();
        await this.getcepages();
        await this.getcouleur();
        await this.getconditionnement();
        this.uuidvin = this.$route.params.uuidvin;
        await this.createyear();
        await this.getenumerations();
        await this.getvin();
        switch (this.couleur) {
            case 'Rouge':
                this.iconColor = '#D2042D';
                break;
            case 'Rosé':
                this.iconColor = '#F8C8DC';
                break;
            case 'Blanc':
                this.iconColor = '#F2F2B2';
                break;
            default:
                this.iconColor = 'white';
                break;
        }
    },
    methods: {
        majAllergene(info) {
        const regex = /\(([^)]+)\)/g;
        const resultString = info.replace(regex, (match, word) => {
            const uppercaseWord = word.toUpperCase();
            return `(${uppercaseWord})`;
        });

        return resultString;
        },
        afficheinfo(param) {
            let resp = '';
            const paramNew = param.filter((str) => str !== '');
            if (paramNew.length > 1) {
                resp = paramNew.join(', ');
            } else if (paramNew.length === 1) {
                [resp] = paramNew;
            }
            return this.majAllergene(resp);
        },
        afficheInfoConservAntiox(param) {
            let resp = '';
            const res = [];
            const paramNew = param.filter((str) => str !== '');
            for (let i = 0; i < paramNew.length; i += 1) {
                const { allergene } = this.arrconservateuretantioxydants.find(({ nom }) => nom === paramNew[i]);
                if (allergene) {
                    res.push(`${paramNew[i]} (${allergene.toUpperCase()})`);
                } else {
                    res.push(`${paramNew[i]}`);
                }
            }
            if (res.length > 1) {
                resp = res.join(', ');
            } else if (res.length === 1) {
                [resp] = res;
            }
            return resp;
        },
        afficheInfoActivateurFerm(param) {
            let resp = '';
            const res = [];
            const paramNew = param.filter((str) => str !== '');
            for (let i = 0; i < paramNew.length; i += 1) {
                const { allergene } = this.arractivateursfermentation.find(({ nom }) => nom === paramNew[i]);
                if (allergene) {
                    res.push(`${paramNew[i]} (${allergene.toUpperCase()})`);
                } else {
                    res.push(`${paramNew[i]}`);
                }
            }
            if (res.length > 1) {
                resp = res.join(', ');
            } else if (res.length === 1) {
                [resp] = res;
            }
            return resp;
        },
        afficheInfoAgentsStab(param) {
            let resp = '';
            const res = [];
            const paramNew = param.filter((str) => str !== '');
            for (let i = 0; i < paramNew.length; i += 1) {
                const { allergene } = this.arragentsstabilisateurs.find(({ nom }) => nom === paramNew[i]);
                if (allergene) {
                    res.push(`${paramNew[i]} (${allergene.toUpperCase()})`);
                } else {
                    res.push(`${paramNew[i]}`);
                }
            }
            if (res.length > 1) {
                resp = res.join(', ');
            } else if (res.length === 1) {
                [resp] = res;
            }
            return resp;
        },
        afficheInfoClarifiant(param) {
            let resp = '';
            const res = [];
            const paramNew = param.filter((str) => str !== '');
            for (let i = 0; i < paramNew.length; i += 1) {
                const { allergene } = this.arragentsclarifiants.find(({ nom }) => nom === paramNew[i]);
                if (allergene) {
                    res.push(`${paramNew[i]} (${allergene.toUpperCase()})`);
                } else {
                    res.push(`${paramNew[i]}`);
                }
            }
            if (res.length > 1) {
                resp = res.join(', ');
            } else if (res.length === 1) {
                [resp] = res;
            }
            return resp;
        },
        formatItemText(item) {
            let result = `${item.nom}`;
            if (item.code !== undefined) {
                result += ` ${item.code}`;
            }
            if (item.allergene !== undefined) {
                result += ` (${item.allergene.toUpperCase()})`;
            }
            return result;
        },
        creerindexappellation() {
            this.appellationarridx = {};
            for (let i = 0; i < this.arrappellation.length; i += 1) {
                this.appellationarridx[this.arrappellation[i].UUID] = this.arrappellation[i].Appellation;
            }
        },
        rechercheappelationParUUID(uuidRecherche) {
            return this.appellationarridx[uuidRecherche] || uuidRecherche;
        },
        creerindexcepage() {
            this.cepagearridx = {};
            for (let i = 0; i < this.arrcepage.length; i += 1) {
                this.cepagearridx[this.arrcepage[i].UUID] = this.arrcepage[i].Cepage;
            }
        },
        recherchecepageParUUID(uuidRecherche) {
            return this.cepagearridx[uuidRecherche] || -1;
        },
        createyear() {
            this.arryeartmp = Array.from({ length: 201 }, (v, i) => (i + (new Date().getFullYear() - 200)).toString());
            this.arrmillesime = this.arryeartmp.reverse();
            this.arrmillesime.unshift('Brut Sans Année');
            this.arrmillesime.unshift('Non millésimé');
        },
        async getappellations() {
            const vm = this;
            const url = `${vm.$config.API_ENDPOINT}/4DACTION/ksf_web_getallappellations`;
            await vm.$axios.get(url).then((response) => {
                vm.arrappellation = response.data;
                vm.arrappellationCopy = [...vm.arrappellation];
                vm.creerindexappellation();
            }).catch((error) => {
                this.errorMessage = error.message;
                console.error('There was an error!', error);
            });
        },
        async getcepages() {
            const vm = this;
            const url = `${vm.$config.API_ENDPOINT}/4DACTION/ksf_web_getallcepages`;

            await vm.$axios.get(url).then((response) => {
                vm.arrcepage = response.data;
                vm.arrcepageCopy = [...vm.arrcepage];
                vm.creerindexcepage();
            }).catch((error) => {
                this.errorMessage = error.message;
                console.error('There was an error!', error);
            });
        },
        async getcouleur() {
            const vm = this;
            const url = `${vm.$config.API_ENDPOINT}/4DACTION/ksf_web_getcouleur`;
            const response = await vm.$axios.get(url);
            vm.arrcouleurs = response.data.couleur;
            vm.arrcouleursCopy = JSON.parse(JSON.stringify(vm.arrcouleurs));
        },
        async getconditionnement() {
            const vm = this;
            const url = `${vm.$config.API_ENDPOINT}/4DACTION/ksf_web_getconditionnement`;
            const response = await vm.$axios.get(url);
            vm.arrconditionnement = response.data;
        },
        async getenumerations() {
            const vm = this;
            const url = `${vm.$config.API_ENDPOINT}/4DACTION/ksf_web_getenumerations`;

            let resultats = '';
            const response = await vm.$axios.get(url);
            vm.arrenumerations = response.data;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'liqueurtirage');
            vm.arrliqueurtirage = resultats[0].liste.liqueurtirage;
            resultats = vm.arrenumerations.filter((word) => word.nom === 'liqueurexpedition');
            vm.arrliqueurexpedition = resultats[0].liste.liqueurexpedition;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'regulateursacidite');
            vm.arrregulateursacidite = resultats[0].liste.regulateursacidite;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'conservateuretantioxydants');
            vm.arrconservateuretantioxydants = resultats[0].liste.conservateuretantioxydants;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'sequestrants');
            vm.arrsequestrants = resultats[0].liste.sequestrants;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'activateursfermentationalcooliquemalolactique');
            vm.arractivateursfermentation = resultats[0].liste.activateursfermentationalcooliquemalolactique;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'agentsclarifiants');
            vm.arragentsclarifiants = resultats[0].liste.agentsclarifiants;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'agentsstabilisateurs');
            vm.arragentsstabilisateurs = resultats[0].liste.agentsstabilisateurs;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'enzymes');
            vm.arrenzymes = resultats[0].liste.enzymes;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'gazetgazemballage');
            vm.arrgazetgazemballage = resultats[0].liste.gazetgazemballage;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'agentsfermentation');
            vm.arragentsfermentation = resultats[0].liste.agentsfermentation;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'correctiondefauts');
            vm.arrcorrectiondefauts = resultats[0].liste.correctiondefauts;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'autrespratiques');
            vm.arrautrespratiques = resultats[0].liste.autrespratiques;
        },
        async getvin() {
            const vm = this;
            const queryscan = this.$route.query.scan;
            const url = `${vm.$config.API_ENDPOINT}/4DACTION/ksf_web_getvin?cb=${Date.now()}`;

            const bodyFormData = new FormData();
            bodyFormData.append('uuidvin', this.uuidvin);
            bodyFormData.append('queryscan', queryscan);

            const datavin = await vm.$axios.post(url, bodyFormData).then((response) => {
                const rep = response.data[0];
                return rep;
            }).catch((error) => {
                this.errorMessage = error.message;
                console.error('There was an error!', error);
            });

            vm.nomduvin = datavin.nomduvin;
            vm.numerolot = datavin.numerolot;
            vm.millesime = datavin.millesime;
            const searchresponse = vm.rechercheappelationParUUID(datavin.uuid_appellation);
            if (searchresponse !== -1) {
                vm.appellation = searchresponse;
            }
            vm.couleur = datavin.couleur;
            vm.categorie = datavin.categorie;
            vm.conditionnement = datavin.conditionnement.split(',');
            vm.tauxdalcool = datavin.tauxalcool;
            vm.cepage = datavin.cepagesuuids.cepages;
            vm.valenerkj = datavin.valenerkj;
            vm.valenerkcal = datavin.valenerkcal;
            vm.glucides = datavin.glucides;
            vm.sucreresiduel = datavin.sucreresiduel;
            vm.checkboxfaiblequant = datavin.checkboxfaiblequant;
            vm.ingredients = datavin.ingredients.split(',');
            vm.liqueurtirage = datavin.liqueurtirage.split(',');
            vm.liqueurexpedition = datavin.liqueurexpedition.split(',');
            vm.regulateursacidite = datavin.regulateursacidite.split(',');
            vm.conservateuretantioxydants = datavin.conservateuretantioxydants.split(',');
            vm.sequestrants = datavin.sequestrants.split(',');
            vm.activateursfermentation = datavin.activateursfermentation.split(',');
            vm.agentsclarifiants = datavin.agentsclarifiants.split(',');
            vm.agentsstabilisateurs = datavin.agentsstabilisateurs.split(',');
            vm.enzymes = datavin.enzymes.split(',');
            vm.checkboxatmoprotect = datavin.checkboxatmoprotect;
            vm.gazetgazemballage = datavin.gazetgazemballage.split(',');
            vm.agentsfermentation = datavin.agentsfermentation.split(',');
            vm.correctiondefauts = datavin.correctiondefauts.split(',');
            vm.autrespratiques = datavin.autrespratiques.split(',');

            vm.photovin = `${vm.$config.API_ENDPOINT}/4DACTION/ksf_web_get_image/?vuuid=${vm.uuidvin}&type=photovin&vn_Taille=256&cb=${Date.now()}`;
            vm.photoetiquetteface = `${vm.$config.API_ENDPOINT}/4DACTION/ksf_web_get_image/?vuuid=${vm.uuidvin}&type=etiquetteface&vn_Taille=256&cb=${Date.now()}`;
            vm.photoetiquettedos = `${vm.$config.API_ENDPOINT}/4DACTION/ksf_web_get_image/?vuuid=${vm.uuidvin}&type=etiquettedos&vn_Taille=256&cb=${Date.now()}`;
            vm.qrcode = `${vm.$config.API_ENDPOINT}/4DACTION/ksf_web_get_image/?vuuid=${vm.uuidvin}&type=qrcode&vn_Taille=256&cb=${Date.now()}`;
        },
        modifiervin() {
            this.$router.push({
                path: `/modifiervin/${this.uuidvin}`
            });
        },
        async downloadimageandconvert(url) {
            const myurl = url;
            const toDataURL = await fetch(myurl)
                .then((response) => response.blob())
                .then((blob) => new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                }));
            return toDataURL;
        },
        async exporter() {
            const photovin = await this.downloadimageandconvert(this.photovin);
            const photoetiquetteface = await this.downloadimageandconvert(this.photoetiquetteface);
            const photoetiquettedos = await this.downloadimageandconvert(this.photoetiquettedos);
            const qrcode = await this.downloadimageandconvert(this.qrcode);
            const zip = new JSZip();
            const arrtxt = [];

            const searchresponse = this.rechercheappelationParUUID(this.appellation);
            if (searchresponse !== -1) {
                this.appellationtxt = searchresponse;
            }
            this.cepagearrtxt = [];
            for (let i = 0; i < this.cepage.length; i += 1) {
                const searchresponsecepage = this.recherchecepageParUUID(this.cepage[i]);
                if (searchresponsecepage !== -1) {
                    this.cepagearrtxt.push(searchresponsecepage);
                }
            }

            arrtxt.push(`Nom du vin : ${this.nomduvin}`);
            arrtxt.push(`Millésime : ${this.millesime}`);
            arrtxt.push('Cépages :');
            for (let i = 0; i < this.cepagearrtxt.length; i += 1) {
                arrtxt.push(`${this.cepagearrtxt[i]}`);
            }
            arrtxt.push(`Appellation : ${this.appellationtxt}`);
            arrtxt.push(`Catégorie : ${this.categorie}`);
            arrtxt.push(`Couleur : ${this.couleur}`);
            arrtxt.push(`Conditionnement : ${this.conditionnement}`);
            arrtxt.push(`Titre alcoométrique volumique acquis (TAVA) : ${this.tauxdalcool} % vol.`);

            const txt = arrtxt.join('\n');
            const filename = this.nomduvin.replace(/[^a-z0-9]/gi, '_').toLowerCase();

            zip.file(`${filename}.txt`, txt);
            const img = zip.folder('images');
            img.file('photovin.jpg', photovin.substr(photovin.indexOf(',') + 1), { base64: true });
            img.file('photoetiquette.jpg', photoetiquetteface.substr(photoetiquetteface.indexOf(',') + 1), { base64: true });
            img.file('photocontreetiquette.jpg', photoetiquettedos.substr(photoetiquettedos.indexOf(',') + 1), { base64: true });
            img.file('codeqr.png', qrcode.substr(qrcode.indexOf(',') + 1), { base64: true });

            zip.generateAsync({ type: 'blob' })
                .then((content) => {
                    // see FileSaver.js
                    saveAs(content, `${filename}.zip`);
                });
        }
    },
};
</script>
<style>
.v-input__icon {
    display: none;
}

.v-menu {
    display: none;
}

.titleColor {
    color: #4a9945;
}

.infoVin {
    margin-top: 15px;
    margin-bottom: 15px;
}

.subtitle {
    margin-top: 10px;
    font-size: 15px;
}

.centerHorizontalVertical {
    display: flex;
    align-items: center;
    /* Vertical alignment */
    justify-content: center;
    /* Horizontal alignment */
    height: 100%;
}
</style>
