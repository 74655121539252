var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "", tile: "" } },
    [
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 30000, top: "", color: _vm.color, tile: "" },
          model: {
            value: _vm.showResult,
            callback: function ($$v) {
              _vm.showResult = $$v
            },
            expression: "showResult",
          },
        },
        [_vm._v(" " + _vm._s(_vm.result) + " ")]
      ),
      _c(
        "v-card-text",
        [
          _c(
            "v-container",
            [
              _c(
                "v-form",
                {
                  attrs: { name: "formvin" },
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c("h1", { staticClass: "titleColor" }, [
                    _vm._v(_vm._s(_vm.nomduvin)),
                  ]),
                  _c("h3", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.categorie) +
                        " " +
                        _vm._s(_vm.appellation)
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "infoVin" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-carousel",
                                {
                                  attrs: {
                                    height: "200",
                                    "hide-delimiters": "",
                                  },
                                },
                                [
                                  _c(
                                    "v-carousel-item",
                                    { key: "0", attrs: { "max-width": "200" } },
                                    [
                                      _c("img", {
                                        attrs: {
                                          width: "200",
                                          src: _vm.photovin,
                                          alt: "photovin",
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "v-carousel-item",
                                    { key: "1", attrs: { "max-width": "200" } },
                                    [
                                      _c("img", {
                                        attrs: {
                                          width: "200",
                                          src: _vm.photoetiquetteface,
                                          alt: "photoetiquetteface",
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "v-carousel-item",
                                    { key: "2", attrs: { "max-width": "200" } },
                                    [
                                      _c("img", {
                                        attrs: {
                                          width: "200",
                                          src: _vm.photoetiquettedos,
                                          alt: "photoetiquettedos",
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-col", [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "text-align": "center",
                                  "margin-top": "50px",
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: { size: "64", color: _vm.iconColor },
                                  },
                                  [_vm._v(" mdi-glass-wine ")]
                                ),
                                _c("h3", [
                                  _vm._v(_vm._s(_vm.tauxdalcool) + " % vol."),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("h2", { staticClass: "titleColor" }, [
                    _vm._v("Déclaration nutritionnelle"),
                  ]),
                  _c("p", { staticClass: "subtitle" }, [_vm._v("Pour 100ml")]),
                  _c(
                    "div",
                    { staticClass: "infoVin" },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", [_c("h4", [_vm._v("Energie")])]),
                          _c("v-col", { attrs: { cols: "auto" } }, [
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.valenerkj) +
                                  " kJ / " +
                                  _vm._s(_vm.valenerkcal) +
                                  " kcal"
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c("v-divider", {
                        staticStyle: { "margin-bottom": "10px" },
                      }),
                      _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c("h4", [_vm._v("Glucides")]),
                            _c("p", [_vm._v("dont sucre")]),
                          ]),
                          _c("v-col", { attrs: { cols: "auto" } }, [
                            _c("p", { staticStyle: { "margin-bottom": "0" } }, [
                              _vm._v(_vm._s(_vm.glucides) + " g"),
                            ]),
                            _c("p", [_vm._v(_vm._s(_vm.sucreresiduel) + " g")]),
                          ]),
                        ],
                        1
                      ),
                      _c("v-divider", {
                        staticStyle: { "margin-bottom": "10px" },
                      }),
                      _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _vm.checkboxfaiblequant
                              ? _c("p", [
                                  _vm._v(
                                    " Contient de faibles quantités de matières grasses, d'acides gras saturés, de protéines et de sel"
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c("h2", { staticClass: "titleColor" }, [
                              _vm._v("Ingrédients"),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "infoVin",
                      staticStyle: { "font-size": "13px" },
                    },
                    [
                      _vm.ingredients != ""
                        ? _c("div", [
                            _c("b", [_vm._v("Ingrédients")]),
                            _vm._v(
                              ": " + _vm._s(_vm.afficheinfo(_vm.ingredients))
                            ),
                          ])
                        : _vm._e(),
                      _vm.liqueurtirage != ""
                        ? _c("div", [
                            _c("b", [_vm._v("Liqueur de tirage")]),
                            _vm._v(
                              ": " + _vm._s(_vm.afficheinfo(_vm.liqueurtirage))
                            ),
                          ])
                        : _vm._e(),
                      _vm.liqueurexpedition != ""
                        ? _c("div", [
                            _c("b", [_vm._v("Liqueur d'éxpedition")]),
                            _vm._v(
                              ": " +
                                _vm._s(_vm.afficheinfo(_vm.liqueurexpedition)) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _vm.regulateursacidite != ""
                        ? _c("div", [
                            _c("b", [_vm._v("Régulateur d'acidité")]),
                            _vm._v(
                              ": " +
                                _vm._s(_vm.afficheinfo(_vm.regulateursacidite))
                            ),
                          ])
                        : _vm._e(),
                      _vm.conservateuretantioxydants != ""
                        ? _c("div", [
                            _c("b", [_vm._v("Conservateurs et antioxydants")]),
                            _vm._v(
                              ": " +
                                _vm._s(
                                  _vm.afficheInfoConservAntiox(
                                    _vm.conservateuretantioxydants
                                  )
                                )
                            ),
                          ])
                        : _vm._e(),
                      _vm.sequestrants != ""
                        ? _c("div", [
                            _c("b", [_vm._v("Séquestrants")]),
                            _vm._v(
                              ": " + _vm._s(_vm.afficheinfo(_vm.sequestrants))
                            ),
                          ])
                        : _vm._e(),
                      _vm.activateursfermentation != ""
                        ? _c("div", [
                            _c("b", [
                              _vm._v(
                                "Activateurs de fermentation alcoolique et malolactique"
                              ),
                            ]),
                            _vm._v(
                              ": " +
                                _vm._s(
                                  _vm.afficheInfoActivateurFerm(
                                    _vm.activateursfermentation
                                  )
                                )
                            ),
                          ])
                        : _vm._e(),
                      _vm.agentsclarifiants != ""
                        ? _c("div", [
                            _c("b", [_vm._v("Agents clarifiants")]),
                            _vm._v(
                              ": " +
                                _vm._s(
                                  _vm.afficheInfoClarifiant(
                                    _vm.agentsclarifiants
                                  )
                                )
                            ),
                          ])
                        : _vm._e(),
                      _vm.agentsstabilisateurs != ""
                        ? _c("div", [
                            _c("b", [_vm._v("Agents stabilisateurs")]),
                            _vm._v(
                              ": " +
                                _vm._s(
                                  _vm.afficheInfoAgentsStab(
                                    _vm.agentsstabilisateurs
                                  )
                                )
                            ),
                          ])
                        : _vm._e(),
                      _vm.enzymes != ""
                        ? _c("div", [
                            _c("b", [_vm._v("Enzymes")]),
                            _vm._v(": " + _vm._s(_vm.afficheinfo(_vm.enzymes))),
                          ])
                        : _vm._e(),
                      _vm.gazetgazemballage != ""
                        ? _c("div", [
                            _c("b", [_vm._v("Gaz et gaz d'emballage")]),
                            _vm._v(
                              ": " +
                                _vm._s(_vm.afficheinfo(_vm.gazetgazemballage))
                            ),
                          ])
                        : _vm._e(),
                      _vm.agentsfermentation != ""
                        ? _c("div", [
                            _c("b", [_vm._v("Agents de fermentation")]),
                            _vm._v(
                              ": " +
                                _vm._s(_vm.afficheinfo(_vm.agentsfermentation))
                            ),
                          ])
                        : _vm._e(),
                      _vm.correctiondefauts != ""
                        ? _c("div", [
                            _c("b", [_vm._v("Correction des défauts")]),
                            _vm._v(
                              ": " +
                                _vm._s(_vm.afficheinfo(_vm.correctiondefauts))
                            ),
                          ])
                        : _vm._e(),
                      _vm.autrespratiques != ""
                        ? _c("div", [
                            _c("b", [_vm._v("Autres pratiques")]),
                            _vm._v(
                              ": " +
                                _vm._s(_vm.afficheinfo(_vm.autrespratiques))
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }